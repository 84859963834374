<template>
    <div class="flex employee-details">
        <aside class="card menu self-start sticky top-0 overflow-y-auto">
            <div class="flex flex-col items-center mt-4 mb-2 pb-4 px-4 border-b">
                <Avatar :avatarSrc="avatarSrc" @change="getBasicInfo" />
                <p class="mt-3 text-sm">
                    {{ name }} {{ surname }} <span v-if="!name" class="opacity-0">.</span>
                </p>
            </div>
            <ul class="py-4">
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'general' }">
                    <router-link
                        :to="{ name: 'employees_details_information', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'general' }"
                    >
                        <fa-icon :icon="['fas', 'info']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.general') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'assigned_addresses' }">
                    <router-link
                        :to="{ name: 'employee_assigned_addresses', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu == 'assigned_addresses' }"
                    >
                        <fa-icon :icon="['fad', 'home']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.assigned_addresses') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'files' }">
                    <router-link
                        :to="{ name: 'employees_details_files', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu == 'files' }"
                    >
                        <fa-icon :icon="['fad', 'folder-open']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.files') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'address' }">
                    <router-link
                        :to="{ name: 'employees_details_address', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'address' }"
                    >
                        <fa-icon :icon="['fad', 'home-alt']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.address') }}
                        </p>
                    </router-link>
                </li>
                <li v-if="userCan('read keys')" class="py-1" :class="{ 'pl-4': activeMenu !== 'keys' }">
                    <router-link
                        :to="{ name: 'employees_details_keys', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'keys' }"
                    >
                        <fa-icon :icon="['fas', 'key']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.keys') }} ({{ keysAmount }})
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'history' }">
                    <router-link
                        :to="{ name: 'employees_details_history', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'history' }"
                    >
                        <fa-icon :icon="['fad', 'history']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.history') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'comments' }">
                    <router-link
                        :to="{ name: 'employees_details_comments', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'comments' }"
                    >
                        <fa-icon :icon="['fad', 'comments']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.comments') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'exclude' }">
                    <router-link
                        :to="{ name: 'employees_details_exclude', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'exclude' }"
                    >
                        <fa-icon :icon="['fad', 'ban']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.exclude') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'damages' }">
                    <router-link
                        :to="{ name: 'employees_details_damages', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'damages' }"
                    >
                        <fa-icon :icon="['fad', 'fragile']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.damages') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'assigned_service_categories' }">
                    <router-link
                        :to="{ name: 'employees_details_assigned_service_categories', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'assigned_service_categories' }"
                    >
                        <fa-icon :icon="['fas', 'broom']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.assigned_categories') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'employee_reminders' }">
                    <router-link
                        :to="{ name: 'employees_details_reminders', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'employee_reminders' }"
                    >
                        <fa-icon :icon="['fad', 'bell-on']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.reminders') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'emergency_contacts' }">
                    <router-link
                        :to="{ name: 'employees_emergency_contacts', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu == 'emergency_contacts' }"
                    >
                        <fa-icon :icon="['fad', 'hands-helping']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.emergency_contacts') }}
                        </p>
                    </router-link>
                </li>
                <!-- <li class="py-1" :class="{ 'pl-4': activeMenu !== 'statistics' }">
                    <router-link
                        :to="{ name: 'employees_details_information', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'statistics' }"
                    >
                        <fa-icon :icon="['fad', 'chart-pie']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.statistics') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'payouts' }">
                    <router-link
                        :to="{ name: 'employees_details_information', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'payouts' }"
                    >
                        <fa-icon :icon="['fad', 'sack-dollar']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.payouts') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'ratings' }">
                    <router-link
                        :to="{ name: 'employees_details_information', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'ratings' }"
                    >
                        <fa-icon :icon="['fas', 'star']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.ratings') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'availability' }">
                    <router-link
                        :to="{ name: 'employees_details_information', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'availability' }"
                    >
                        <fa-icon :icon="['fad', 'business-time']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.availability') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'absence' }">
                    <router-link
                        :to="{ name: 'employees_details_information', params: { uuid: employeeUuid }}"
                        class="flex py-2 items-center"
                        :class="{ 'active': activeMenu === 'absence' }"
                    >
                        <fa-icon :icon="['fad', 'empty-set']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('employee_details.absence') }}
                        </p>
                    </router-link>
                </li> -->
            </ul>
        </aside>
        <transition mode="out-in">
            <router-view @activeSubpage="changeActiveMenu" @onKeysNumberChanged="getKeysNumber" />
        </transition>
    </div>
</template>
<script>
export default {
    components: {
        Avatar: () => import(/* webpackChunkName: "EmployeeDetails/EmployeeDetailsAvatar" */ './components/EmployeeDetailsAvatar.vue'),
    },

    data() {
        return {
            employeeUuid: this.$route.params.uuid,
            name:         '',
            surname:      '',
            avatarSrc:    '',
            activeMenu:   'general',
            keysAmount:   0,
        };
    },

    async created() {
        this.$store.commit('setPageTitle', this.$t('employees.employee_details'));
        this.$store.commit('setActiveMenuItem', '3-1');
        this.getBasicInfo();
        this.getKeysNumber();
    },

    methods: {
        changeActiveMenu(activeMenu) {
            this.activeMenu = activeMenu;
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 50);
        },

        async getBasicInfo() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}`);
            this.name = data.name;
            this.surname = data.surname;
            this.avatarSrc = data.avatar_src;

            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 50);
        },

        async getKeysNumber() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.employeeUuid}/keys_holder_number`);
            this.keysAmount = data;
        },
    },
};
</script>
<style scoped>
    .menu {
        width: 350px;
        margin-right: 15px;
    }

    .active {
        @apply bg-gray-200 px-4 py-2 rounded relative;
        transition: 250ms ease background-color, 250ms ease color;
    }

    .active svg{
        color: #f26364 !important;
    }

    .active p {
        @apply font-semibold;
        color: #f26364 !important;
    }
</style>
<style>
    .employee-details-menu .el-button{
        padding: 0;
    }
</style>
